import React from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '@/constants/colors';
import checkmark from '@/assets/images/checkmark.svg';
import { companySelector, cartSelector } from '@/store/selector';
import { useDispatch } from 'react-redux';
import { addItem, setItemQuantity, resetCart } from '@/components/cartSlice';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import BookingButton from '@/components/Buttons/BookingButton';
import CartHelper from '@/helpers/cartHelper';
import 'flickity-bg-lazyload';
import { searchSelector } from '@/store/selector';
import '@/assets/flickity.css';
import { BrowserView, MobileView } from 'react-device-detect';
import Breakpoints from '@/constants/breakpoints';
import Carousel from '../Carousel/Carousel';

const useStyles = createUseStyles(
  {
    root: {
      display: 'block',
      float: 'left',
      margin: '0 0 40px 65px',
      position: 'relative',
      width: 'calc(33.3333% - 88px)',
    },
    actions: {
      height: '38px',
      position: 'relative',
    },
    message: {
      color: Colors.greenOne,
      fontSize: '1.0rem',
      lineHeight: '18px',
      margin: 0,
    },
    messageTwo: {
      color: Colors.redOne,
      fontSize: '1.0rem',
      lineHeight: '18px',
      margin: 0,
    },
    roomAdded: {
      color: Colors.greenOne,
      opacity: 1,
      pointerEvents: 'all',
      transition: 'opacity .3s ease .3s',
      padding: '0 0 0 42px',
      left: 0,
      position: 'absolute',
      top: 0,
      '& > span': {
        background: `url(${checkmark}) no-repeat 0 0 transparent`,
        backgroundSize: '21px 18px',
        display: 'inline-block',
        height: '18px',
        width: '21px',
        marginRight: '42px',
        left: 0,
        position: 'absolute',
      },
    },
    removeRoomAdded: {
      opacity: 1,
      pointerEvents: 'all',
      transition: 'opacity 300ms ease 300ms',
      color: Colors.blackOne,
      cursor: 'pointer',
      // font-family: "Work-Sans", sans-serif',
      position: 'absolute',
      right: 0,
      top: 0,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
        color: Colors.blackOne,
      },
    },
    card: {
      border: `1px solid ${Colors.grayOne}`,
      position: 'relative',
    },
    imageContainer: {
      borderBottom: `1px solid ${Colors.grayOne}`,
      backgroundColor: Colors.whiteOne,
      display: 'block',
      height: 0,
      paddingBottom: '74%',
      position: 'relative',
      width: '100%',
      '& .slider': {
        height: '100%',
        width: '100%',
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
    image: {
      opacity: 0.2,
      left: '0%',
      width: '100%',
      height: '100%',
      zIndex: 0,
      position: 'absolute',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    imageInside: {
      width: '133%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    content: {
      height: '350px',
      padding: '20px',
      position: 'relative',
    },
    location: {
      color: Colors.grayThree,
      //fontFamily: 'Work-Sans,sans-serif',
      fontSize: '0.8rem',
      letterSpacing: '1.4px',
      lineHeight: 'normal',
      margin: 0,
      textTransform: 'uppercase',
    },
    shortDescription: {
      fontSize: '1.2rem',
      maxHeight: '1.5rem',
      overflowY: 'hidden',
    },
    description: (properties) => ({
      //fontFamily: 'Avenir-Book,sans-serif',
      fontSize: '1.0rem',
      margin: '20px 0 0',
      maxHeight: '7rem',
      overflowY: 'auto',
      paddingRight: '5px',
      '&::-webkit-scrollbar': {
        width: '0.5rem;',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: properties.propertyColor,
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: properties.propertyColor,
      },
    }),
    [`@media (max-width: ${Breakpoints.md}px)`]: {
      description: {
        maxHeight: '75px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    attributes: {
      overflowWrap: 'break-word',
      minHeight: '2.5rem',
      maxHeight: '2.5rem',
      overflowY: 'hidden',
      textOverflow: 'ellipsis',
      color: Colors.grayThree,
      //fontFamily: 'Work-Sans,sans-serif',
      fontSize: '0.8rem',
      letterSpacing: '1.4px',
      lineHeight: 'normal',
    },
    bookQuantity: {
      opacity: 1,
      pointerEvents: 'all',
      transition: 'opacity 300ms ease 300ms',
      alignItems: 'center',
      border: `1px solid ${Colors.grayOne}`,
      display: 'flex',
      textAlign: 'center',
      bottom: '30px',
      // font-family: "Canela-Regular", serif;
      fontSize: '1.8rem',
      height: '60px',
      left: '30px',
      letterSpacing: 0,
      lineHeight: '60px',
      position: 'absolute',
      textTransform: 'none',
      width: 'calc(100% - 60px)',
      '& > p': {
        //font-family: "Work-Sans", sans-serif;
        fontSize: '1.1rem',
        lineHeight: 1.3,
        margin: 0,
        padding: '0 20px 0 0',
        textAlign: 'left',
      },
    },
    bookQuantitySelect: {
      // background: `url(${dropdown_arrow}) no-repeat right 6px transparent`,
      backgroundColor: Colors.whiteOne,
      border: '0 none',
      borderBottom: `1px solid ${Colors.grayOne}`,
      // font-family: "Work-Sans", sans-serif,
      fontSize: '1.1rem',
      display: 'block',
      margin: '0 15px 0 18px',
      padding: '0 0 4px 10px',
      width: '46px',
      cursor: 'pointer',
      outline: '0px',
    },
    [`@media (max-width: 1200px)`]: {
      root: {
        width: 'calc(100% - 60px)',
        margin: '0 0 40px 30px !important',
      },
    },
  },
  { name: 'RoomTypeItem' },
);

const RoomTypeItem = ({
  bookingSearchByDatesResponseItem,
  bestAvailableRate,
  defaultRoomTypeImage,
  handleItemSelected,
}) => {
  const company = companySelector();
  const cart = cartSelector();
  const dispatch = useDispatch();
  const classes = useStyles({
    propertyColor: company.property.color,
  });
  const search = searchSelector();
  const itemInCart = CartHelper.findItemByRoomType(cart.items, bookingSearchByDatesResponseItem);
  const cartProperty = CartHelper.findCartProperty(cart);
  const isCartPropertyMismatch =
    !!cartProperty && bookingSearchByDatesResponseItem.roomType.property.id != cartProperty.id;
  const isRoomTypeOnCart = !!itemInCart;
  //const [heroImageStyle, setHeroImageStyle] = useState({});
  let heroImageStyle = null;
  if (
    bookingSearchByDatesResponseItem.roomType != null &&
    bookingSearchByDatesResponseItem.roomType.imageDataList != null &&
    bookingSearchByDatesResponseItem.roomType.imageDataList.length > 0
  ) {
    heroImageStyle = {
      backgroundImage:
        'url("' +
        encodeURI(bookingSearchByDatesResponseItem.roomType.imageDataList[0].imageUrl) +
        '")',
      backgroundSize: 'cover',
    };
  }

  const onClickBooking = () => dispatch(addItem(bookingSearchByDatesResponseItem));
  const onClickResetCart = () => dispatch(resetCart(bookingSearchByDatesResponseItem));
  const onClickImageContainer = () => {
    handleItemSelected(bookingSearchByDatesResponseItem.roomType);
  };

  const onChangeBookQuantitySelect = (event) => {
    var newItemQuantity = parseInt(event.target.value);
    dispatch(setItemQuantity({ item: itemInCart, newItemQuantity: newItemQuantity }));
  };

  const bookQuantitySelectOptions = () => {
    var bookQuantitySelectOptionList = new Array();
    for (let i = 0; i <= bookingSearchByDatesResponseItem.roomsAvailable; i++) {
      bookQuantitySelectOptionList.push(i);
    }
    return bookQuantitySelectOptionList;
  };

  const BookingButtonToShow = (
    <>
      {!isRoomTypeOnCart && isCartPropertyMismatch && (
        <ConfirmationModal
          header="Cart Not Empty"
          trigger={(props) => (
            <BookingButton
              text={`$${bookingSearchByDatesResponseItem.averageNightlyRate} / night - Book`}
              {...props}
            />
          )}
          onConfirm={onClickResetCart}
        >
          Your cart contains rooms at a different resort. Would you like to empty your cart and add
          this room?
        </ConfirmationModal>
      )}
      {!isRoomTypeOnCart && !isCartPropertyMismatch && (
        <BookingButton
          text={`$${bookingSearchByDatesResponseItem.averageNightlyRate} / night - Book`}
          onClick={onClickBooking}
        />
      )}
    </>
  );

  return (
    <article className={classes.root}>
      <div className={classes.actions}>
        {!isRoomTypeOnCart && bestAvailableRate && (
          <p className={classes.message}>Best Available Rate</p>
        )}
        {!isRoomTypeOnCart &&
          !search.property.hideDemand &&
          bookingSearchByDatesResponseItem.roomsAvailable == 1 &&
          !bestAvailableRate && <p className={classes.messageTwo}>Only 1 left!</p>}
        {isRoomTypeOnCart && (
          <>
            <p className={classes.roomAdded}>
              <span></span>Room Added!
            </p>
            <a
              className={classes.removeRoomAdded}
              onClick={() => dispatch(setItemQuantity({ item: itemInCart, newItemQuantity: 0 }))}
            >
              Remove
            </a>
          </>
        )}
      </div>
      <div className={classes.card}>
        <BrowserView>
          <div className={classes.imageContainer}>
            <Carousel
              defaultRoomIamgeUrl={defaultRoomTypeImage}
              imageList={bookingSearchByDatesResponseItem.roomType.imageDataList}
              onClickImageContainer={onClickImageContainer}
            />
          </div>
        </BrowserView>
        <MobileView>
          <div
            className={classes.imageContainer}
            style={heroImageStyle}
            onClick={onClickImageContainer}
          ></div>
        </MobileView>

        <div className={classes.content} onClick={onClickImageContainer}>
          <p className={classes.location}>
            {bookingSearchByDatesResponseItem.roomType.property.name}
          </p>
          <h1 className={classes.shortDescription}>
            {bookingSearchByDatesResponseItem.roomType.name}
          </h1>
          <p className={classes.description}>
            {bookingSearchByDatesResponseItem.roomType.description}
          </p>
          <p className={[classes.description, classes.attributes].join(' ')}>
            Sleeps {bookingSearchByDatesResponseItem.roomType.sleeps}
            {!!bookingSearchByDatesResponseItem.roomType.feets &&
              `, ${bookingSearchByDatesResponseItem.roomType.feets} ft²`}
            {!!bookingSearchByDatesResponseItem.roomType.attributes &&
              ` - ${bookingSearchByDatesResponseItem.roomType.attributes}`}
          </p>
        </div>
        <div className={classes.events}>
          {BookingButtonToShow}
          {isRoomTypeOnCart && (
            <div className={classes.bookQuantity}>
              <select
                value={itemInCart.numberOfRooms}
                className={classes.bookQuantitySelect}
                onChange={onChangeBookQuantitySelect}
              >
                {bookQuantitySelectOptions().map((availabilityOption) => (
                  <option key={availabilityOption} value={availabilityOption}>
                    {availabilityOption}
                  </option>
                ))}
              </select>
              <p>of this room type added to your cart!</p>
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

export default React.memo(RoomTypeItem);
