import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { searchSelector } from '@/store/selector';
import './RoomTypePreviewPanel.css';
import { Grid, GridColumn, Dimmer, Loader } from 'semantic-ui-react';
import Flickity from 'react-flickity-component';
import 'flickity-bg-lazyload';

const useStyles = createUseStyles(
  {
    previewPanel: (properties) => ({
      position: 'fixed',
      width: '100%',
      maxWidth: '1920px',
      height: '100%',
      backgroundColor: properties.propertyColor,
      top: '0',
      left: 'calc(-50vw + 50%)',
      right: 'calc(-50vw + 50%)',
      marginLeft: 'auto',
      marginRight: 'auto',
      transform: 'translateY(100%)',
      transition: 'transform .75s cubic-bezier(.9,1,.32,1)',
      zIndex: 9999999,
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }),
    previewPanelActive: (properties) => ({
      position: 'fixed',
      width: '100%',
      maxWidth: '1920px',
      height: '100%',
      backgroundColor: properties.propertyColor,
      top: '0',
      left: 'calc(-50vw + 50%)',
      right: 'calc(-50vw + 50%)',
      marginLeft: 'auto',
      marginRight: 'auto',
      transform: 'translateY(0%) !important',
      transition: 'transform .75s cubic-bezier(.9,1,.32,1)',
      zIndex: 9999999,
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    }),
    body: (properties) => ({
      color: properties.fontColor,
    }),
    sliderImage: {
      left: '0%',
      width: '100%',
      height: '100%',
      zIndex: 0,
      position: 'absolute',
      backgroundPosition: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    imageContainer: {
      display: 'block',
      height: 0,
      paddingBottom: '74%',
      position: 'relative',
      width: '100%',
      '& .slider': {
        height: '100%',
        width: '100%',
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
    image: {
      opacity: 0.2,
      left: '0%',
      width: '100%',
      height: '100%',
      zIndex: 0,
      position: 'absolute',
      backgroundPosition: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    dimmer: {
      backgroundColor: '#000',
      opacity: '0.8',
      zIndex: 999999,
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: '0',
      transform: 'translateY(100%)',
      transition: 'transform .75s cubic-bezier(.9,1,.32,1)',
    },
    dimmerActive: {
      composes: ['$dimmer'],
      display: 'block',
      transform: 'translateY(0%) !important',
      transition: 'transform .75s cubic-bezier(.9,1,.32,1)',
    },
    dimmerFlickity: (properties) => ({
      backgroundColor: `${properties.propertyColor} !important`,
    }),
  },
  { name: 'RoomTypePreviewPanel' },
);

const RoomTypePreviewPanel = ({ item, show, handleOnClickImageContainer }) => {
  const search = searchSelector();
  const classes = useStyles({
    propertyColor: search.property.color,
    fontColor: search.property.fontColor,
  });
  const [showPreview, setShowPreview] = useState(show);

  useEffect(() => {
    setShowPreview(show);
  }, [show]);

  const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
    bgLazyLoad: true,
    className: 'slider',
  };

  let imageList =
    item !== null && item.imageList !== undefined ? item.imageList : item.imageDataList;

  return (
    <div>
      <div
        className={
          showPreview === true && item !== null && imageList !== null && imageList.length > 0
            ? classes.dimmerActive
            : classes.dimmer
        }
      ></div>
      <div
        className={
          showPreview === true && item !== null && imageList !== null && imageList.length > 0
            ? classes.previewPanelActive
            : classes.previewPanel
        }
      >
        {showPreview === true && item !== null && imageList !== null && imageList.length > 0 && (
          <div className="roomtype-preview">
            <div className="roomtype-preview-head">
              <Grid columns={'3'}>
                <GridColumn></GridColumn>
                <GridColumn className="roomtype-preview-rate" textAlign="center">
                  {item !== null && item.averageNightlyRate !== undefined
                    ? '$' + item.averageNightlyRate + ' / night'
                    : ''}
                </GridColumn>
                <GridColumn textAlign="right">
                  <a
                    className="roomtype-preview-close"
                    onClick={() => {
                      setShowPreview(false);
                      handleOnClickImageContainer(false);
                    }}
                  >
                    Close x
                  </a>
                </GridColumn>
              </Grid>
            </div>
            <div className="roomtype-preview-body">
              <div className={classes.body}>
                <div className="property">{search.property.name}</div>
                <div className="roomtype">{item !== null ? item.name : ''}</div>
                <div className="preview-slider">
                  {showPreview === true &&
                    item !== null &&
                    imageList !== null &&
                    imageList.length > 0 && (
                      <Flickity
                        options={flickityOptions}
                        disableImagesLoaded={false} // default false
                        reloadOnUpdate // default false
                        static
                        wrapAround={true}
                      >
                        {imageList.map((image, idx) => (
                          <div className="slide" key={idx}>
                            <div className="slide-content">
                              <div
                                className={classes.sliderImage}
                                data-flickity-bg-lazyload={encodeURI(
                                  image !== null
                                    ? image.url !== undefined
                                      ? image.url
                                      : image.imageUrl
                                    : '',
                                )}
                              >
                                <Dimmer active inverted className={classes.dimmerFlickity}>
                                  <Loader inverted content="Loading Image" />
                                </Dimmer>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Flickity>
                    )}
                </div>
                <div className="slider-responsive">
                  {showPreview === true &&
                    item !== null &&
                    imageList !== null &&
                    imageList.length > 0 && (
                      <div className={classes.imageContainer}>
                        <Flickity
                          className={'slider'} // default ''
                          elementType={'div'} // default 'div'
                          options={flickityOptions}
                          disableImagesLoaded={false} // default false
                          reloadOnUpdate // default false
                          static
                          wrapAround={true}
                        >
                          {imageList.map((image, idx) => (
                            <div
                              className={classes.image}
                              key={idx}
                              data-flickity-bg-lazyload={encodeURI(
                                image.url !== undefined ? image.url : image.imageUrl,
                              )}
                            >
                              <Dimmer active inverted className={classes.dimmerFlickity}>
                                <Loader inverted content="Loading Image" />
                              </Dimmer>
                            </div>
                          ))}
                        </Flickity>
                      </div>
                    )}
                </div>
                <div className="sleeps">{item !== null ? 'SLEEPS ' + item.sleeps : ''}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default React.memo(RoomTypePreviewPanel);
