import React from 'react';
import { createUseStyles } from 'react-jss';
import Colors from '@/constants/colors';
import { useDispatch } from 'react-redux';
import { setRoomType } from '@/components/searchSlice';
import Carousel from '../Carousel/Carousel';

const useStyles = createUseStyles(
  {
    root: {
      display: 'block',
      float: 'left',
      position: 'relative',
      width: '100%',
    },
    card: {
      border: `1px solid ${Colors.grayOne}`,
      position: 'relative',
    },
    imageContainer: {
      borderBottom: `1px solid ${Colors.grayOne}`,
      backgroundColor: Colors.whiteOne,
      display: 'block',
      height: 0,
      paddingBottom: '74%',
      position: 'relative',
      width: '100%',
      '& .slider': {
        height: '100%',
        width: '100%',
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
    content: {
      padding: '20px',
      position: 'relative',
      height: '168px',
    },
    location: {
      color: Colors.grayThree,
      //fontFamily: 'Work-Sans,sans-serif',
      fontSize: '0.8rem',
      letterSpacing: '1.4px',
      lineHeight: 'normal',
      margin: 0,
      overflowWrap: 'break-word',
      minHeight: '2.5rem',
      maxHeight: '2.5rem',
      overflowY: 'hidden',
      textOverflow: 'ellipsis',
    },
    shortDescription: {
      fontSize: '1.2rem',
      maxHeight: '1.5rem',
      overflowY: 'hidden',
    },
    description: {
      //fontFamily: 'Avenir-Book,sans-serif',
      fontSize: '1.0rem',
      margin: '20px 0 0',
    },
    button: (properties) => ({
      backgroundColor: properties.propertyColor,
      color: properties.fontColor,
      width: '100%',
      display: 'block',
      textAlign: 'center',
      padding: '12px',
      cursor: 'pointer',
      transition: '.5s',
      fontSize: '1.5rem',
      //fontFamily: 'Canela-Regular, serif',
      letterSpacing: '0px',
      '&:hover, &:focus': {
        color: properties.fontColor,
        filter: 'brightness(85%)',
      },
    }),
    [`@media (max-width: 1200px)`]: {
      root: {
        width: '100%',
        marginBottom: '0px',
      },
    },
  },
  { name: 'RoomTypeHomeItem' },
);

const RoomTypeHomeItem = ({ roomType, property, handleItemSelected }) => {
  const dispatch = useDispatch();

  const searchClick = () => {
    dispatch(
      setRoomType({
        roomType: roomType,
      }),
    );
  };

  const onClickImageContainer = () => {
    if (roomType.imageList.length > 0) {
      handleItemSelected(roomType);
    }
  };

  const classes = useStyles({
    propertyColor: property != null ? property.color : '',
    fontColor: property != null ? property.fontColor : '',
  });

  return (
    <article className={classes.root}>
      <div className={classes.card}>
        <div className={classes.imageContainer}>
          <Carousel
            defaultRoomIamgeUrl={property.defaultRoomIamgeUrl}
            imageList={roomType.imageList}
            onClickImageContainer={onClickImageContainer}
          />
        </div>
        <div className={classes.content}>
          <div onClick={onClickImageContainer}>
            <h1 className={classes.shortDescription}>{roomType.name}</h1>
            <p className={classes.location}>
              Sleeps {roomType.sleeps}
              {!!roomType.feets && `, ${roomType.feets} ft²`}
              {!!roomType.attributes && ` - ${roomType.attributes}`}
            </p>
          </div>
          <p className={classes.description}>
            <a className={classes.button} onClick={searchClick}>
              View Availability
            </a>
          </p>
        </div>
      </div>
    </article>
  );
};

export default React.memo(RoomTypeHomeItem);
